export default {
  subsidyTitle: {
    id: 'services_bouygues_offers_subsidy_title',
    defaultMessage:
      'Ça commence bien, avec jusqu’à {subsidy} de remise immédiate en souscrivant à un forfait.',
  },
  subsidyButtonContentOpened: {
    id: 'services_bouygues_offers_subsidy_button_content_opened',
    defaultMessage: 'Masquer les conditions',
  },
  subsidyButtonContentClosed: {
    id: 'services_bouygues_offers_subsidy_button_content_closed',
    defaultMessage: 'Consulter les conditions',
  },
  subsidyContent: {
    id: 'services_bouygues_offers_subsidy_content',
    defaultMessage:
      "Si vous avez déjà un forfait Bouygues Telecom, vous bénéficiez de la remise uniquement avec la création d’un nouveau numéro de téléphone. La subvention est soumise à la souscription d'un forfait Bouygues Telecom. En cas de rétractation du forfait, elle devra être restituée.",
  },
}
