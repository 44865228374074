<template>
  <RevCard v-if="pending">
    <RevSkeleton
      data-test="subsidy-skeleton"
      height="114px"
      shape="rectangle"
      width="100%"
    />
  </RevCard>

  <RevInfoBlock
    v-if="!pending && subsidy"
    :icon="IconPriceTag"
    :title="
      i18n(translations.subsidyTitle, {
        subsidy: i18n.price(subsidy),
      })
    "
    variant="success"
  >
    <RevLink class="caption" @click="isOpen = !isOpen">
      <p v-if="isOpen">
        {{ i18n(translations.subsidyButtonContentOpened) }}
      </p>
      <p v-else>{{ i18n(translations.subsidyButtonContentClosed) }}</p>
    </RevLink>

    <Transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      leave-active-class="opacity-0 transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
    >
      <p
        v-if="isOpen"
        class="text-static-default-mid caption whitespace-pre-line"
      >
        {{ i18n(translations.subsidyContent) }}
      </p>
    </Transition>
  </RevInfoBlock>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconPriceTag } from '@ds/icons/IconPriceTag'

import { getHighestSubsidy } from '~/scopes/product/utils/getHighestSubsidy'

import translations from './SubsidyInfoBlock.translations'

const isOpen = ref(false)
const i18n = useI18n()

const props = defineProps<{
  offers: MobilePlanOffer[] | null
  pending: boolean
}>()

const subsidy = computed(() => {
  if (!props.offers) return null

  return getHighestSubsidy(props.offers)
})
</script>
