<template>
  <RevCard>
    <h1 class="heading-1 px-24 pt-16">
      {{ offer.label }}
    </h1>

    <!-- Keep phone number price option -->
    <div v-if="keepPhoneNumber">
      <div v-if="keepPhoneNumber.prices.length === 1" class="px-24 pb-16 pt-10">
        <p class="body-1 flex flex-col">
          {{
            i18n(translations.onePeriodPrice, {
              price: i18n.price(keepPhoneNumber.prices[0].price),
            })
          }}

          <span class="text-static-default-low caption">
            <EnrollmentPeriod
              :enrollment-period="offer.enrollmentPeriod"
              :enrollment-translation="translations.enrollmentPeriod"
            />
          </span>
        </p>
      </div>

      <RevAccordionItem v-else collapsed="expanded">
        <template #title>
          {{ i18n(translations.priceWhileKeepingTheSamePhoneNumber) }}
        </template>

        <template #body>
          <div>
            <span class="text-static-default-low caption-striked">
              {{
                i18n(translations.onePeriodPrice, {
                  price: i18n.price(keepPhoneNumber.prices[1].price),
                })
              }}
            </span>

            <span class="body-1 ml-2">
              {{
                i18n(translations.onePeriodPrice, {
                  price: i18n.price(keepPhoneNumber.prices[0].price),
                })
              }}
            </span>

            <div
              v-if="keepPhoneNumber.prices[0].monthsDuration"
              class="text-static-default-low caption"
            >
              {{
                i18n(translations.secondPeriodPrice, {
                  duration: getFormattedDuration(
                    keepPhoneNumber.prices[0].monthsDuration,
                  ),
                  secondPeriodPrice: i18n.price(
                    keepPhoneNumber.prices[1].price,
                  ),
                })
              }}
            </div>

            <span class="text-static-default-low caption">
              <EnrollmentPeriod
                :enrollment-period="offer.enrollmentPeriod"
                :enrollment-translation="translations.enrollmentPeriod"
              />
            </span>
          </div>
        </template>
      </RevAccordionItem>

      <RevDivider v-if="newPhoneNumber" />
    </div>

    <!-- New phone number price option -->
    <div
      v-if="
        newPhoneNumber &&
        keepPhoneNumber?.prices[0].price.amount !==
          newPhoneNumber.prices[0].price.amount
      "
    >
      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.priceWhileChangingPhoneNumber) }}
        </template>

        <template #body>
          <div class="flex flex-col">
            <span class="body-1">
              {{
                i18n(translations.onePeriodPrice, {
                  price: i18n.price(newPhoneNumber.prices[0].price),
                })
              }}
            </span>

            <span class="text-static-default-low caption">
              <EnrollmentPeriod
                :enrollment-period="offer.enrollmentPeriod"
                :enrollment-translation="translations.enrollmentPeriod"
              />
            </span>
          </div>
        </template>
      </RevAccordionItem>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'

import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'

import translations from './OfferCard.translations'

const props = defineProps<{ offer: MobilePlanOffer }>()

const i18n = useI18n()

const keepPhoneNumber = computed(() =>
  props.offer.priceOptions.find(
    (priceOption) => priceOption.option === 'KEEP_PHONE_NUMBER',
  ),
)

const newPhoneNumber = computed(() =>
  props.offer.priceOptions.find(
    (priceOption) => priceOption.option === 'NEW_PHONE_NUMBER',
  ),
)

const { getFormattedDuration } = useFormatMonthsYearsDuration()
</script>
