<template>
  <RevDrawer
    :close-button-label="i18n(translations.closeButtonLabel)"
    :name="MODAL_NAMES.BOUYGUES"
    :title="i18n(translations.drawerTitle)"
    @open="fetchOffers"
  >
    <template #body>
      <div class="space-y-24">
        <header class="space-y-8">
          <BouyguesLogo />

          <h1 class="heading-1 whitespace-pre-line">
            {{ i18n(translations.title) }}
          </h1>
        </header>

        <SubsidyInfoBlock :offers="eligibleOffers" :pending="pending" />

        <div v-if="pending" class="space-y-6">
          <OfferCardSkeleton />
          <OfferCardSkeleton />
        </div>

        <div v-if="!pending" class="space-y-8">
          <OfferCard
            v-for="offer in eligibleOffers"
            :key="offer.id"
            :offer="offer"
          />
        </div>

        <div
          v-if="benefits.includes('BBOX_DISCOUNT_5_EUROS')"
          class="flex items-center"
        >
          <div class="text-static-default-low caption mr-32">
            {{ i18n(translations.bboxOffer) }}
          </div>

          <RevTooltip content-class-names="z-20" position="left">
            <template #trigger="{ show, hide }">
              <IconInfo
                @focusin="show"
                @focusout="hide"
                @mouseenter="show"
                @mouseleave="hide"
              />
            </template>
            {{ i18n(translations.bboxOfferTooltip) }}
          </RevTooltip>
        </div>

        <div class="space-y-8">
          <h2 class="heading-2 mt-36">
            {{ i18n(translations.offerIncludeTitle) }}
          </h2>

          <BenefitsList :benefits />
        </div>
      </div>

      <RevInfoBlock
        class="mt-48"
        :content="i18n(translations.keepYourPhoneNumberContent)"
        :icon="IconContact"
        :title="i18n(translations.keepYourPhoneNumberTitle)"
        variant="default"
      />
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getMobilePlanOffers } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import type { Benefit } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevDrawer } from '@ds/components/Drawer'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconContact } from '@ds/icons/IconContact'
import { IconInfo } from '@ds/icons/IconInfo'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './BouyguesOffersDrawer.translations'
import BenefitsList from './components/BenefitsList/BenefitsList.vue'
import OfferCard from './components/OfferCard/OfferCard.vue'
import OfferCardSkeleton from './components/OfferCardSkeleton/OfferCardSkeleton.vue'
import SubsidyInfoBlock from './components/SubsidyInfoBlock/SubsidyInfoBlock.vue'

const i18n = useI18n()
const { market } = useMarketplace()

const props = defineProps<{
  eligibleOffersIds: string[]
}>()

const {
  data: offers,
  pending,
  execute: fetchOffers,
} = useHttpFetch(getMobilePlanOffers, {
  queryParams: {
    marketCode: market.countryCode,
    partner: 'BOUYGUES_TELECOM',
  },
  immediate: false,
})

const eligibleOffers = computed(() => {
  if (!offers.value) return null
  if (!props.eligibleOffersIds) return offers.value

  return offers.value.filter((offer) => {
    return props.eligibleOffersIds?.includes(offer.id)
  })
})

const benefits = computed(() => {
  if (!eligibleOffers.value) return []

  return eligibleOffers.value.reduce<Benefit[]>(
    (accumulatedBenefits, offer) => {
      return [...accumulatedBenefits, ...offer.benefits]
    },
    [],
  )
})
</script>
